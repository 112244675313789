let exclude = ['./case.js']
const objsContext = require.context('./apis/', false, /\.js$/);

const objs = objsContext.keys().reduce((all, file) => {
  // 忽略
  if (exclude.includes(file)) return all;

  // 导入接口配置文件
  const config = objsContext(file);

  // 截取文件名
  const name = file.replace(/\.\/(\w+)\.js/, '$1')

  all[name] = (config.default || config)

  return all;
}, []);
export default objs;
