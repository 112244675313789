import axios from "axios";
import tool from "@/common/tool";
import wx from "@/common/tool/wx";
import tc from "@/common/tool/tc";
import env from "./config";
import qs from "qs";

// 设置
const setting = {
  unLogin: [], // 不需要登陆的页面
  login: "/home", // 登录路径
};
// 处理错误响应
const handleError = error => {
  let err = error.response;
  let message = "";

  // 解析错误信息
  if (err && err.data) {
    message = parseErrorMessage(err);
  }
  // 如果错误信息包含40029或40163，调用wx.code()
  if (message.includes("40029") || message.includes("40163")) {
    wx.code();
    return;
  }

  // 处理HTTP状态错误
  handleHttpStatusError(error, err, message);
};

// 解析错误信息
const parseErrorMessage = err => {
  let message = "";
  if (err.data.errors) {
    Object.values(err.data.errors).forEach((value, index) => {
      if (index === 0) {
        message = value[0]; // 获取第一个错误信息
      }
    });
  } else {
    message = err.data.message; // 获取错误消息
  }
  return message;
};

// 处理HTTP状态错误
const handleHttpStatusError = (error, err, message) => {

  // 如果错误信息包含timeout，显示网络超时的提示
  if (error.message.includes("timeout")) {
    tc.err(`网络超时`)
    return
  }

  // 如果有错误状态码，处理错误状态码
  if (err.status) {
    handleHttpErrorCode(err, message);
  }
};

// 处理HTTP错误状态码
const handleHttpErrorCode = (err, message) => {
  console.log(err, message);
  // 根据不同的状态码进行不同的处理
  switch (err.status) {
    case 401:
      handle401StatusCode(err, message); // 处理401状态码
      break;
    case 404:
      break; // 处理404状态码
    default:
      handleDefaultStatusCode(err, message); // 处理默认状态码
  }
};

// 处理401状态码
const handle401StatusCode = (err, message) => {
  // 服务器返回code值
  let code = err.data.code

  // 1未登录，2Token类型错误，3过期，4其他设备登录
  if (code === 1 || code === 2) {
    unifiedAction("请先登录", false, false); return
  }
  if (code === 3) {
    unifiedAction("登录过期，正在为您重新登录", false, true); return
  }
  if (code === 4) {
    unifiedAction("您的账号已在其他账号登录", false, true); return
  }
  unifiedAction("请先登录", false, true); return
};

// 处理统一动作代码
const unifiedAction = (tip, isLogin, isUpdateToken) => {
  // ...

  // 清理登陆信息
  tool.storage.remove("login-token");
  tool.storage.remove("login-current");
  tool.storage.remove("shop-current");
  tool.storage.remove("user");
  tool.storage.remove("project-user");

  // 去登陆
  isLogin && login()
  // 更新token
  isUpdateToken && updateToken();
  // 提示错误信息
  tip && tc.err(tip);
};

// 登陆
const login = () => {
  tc.countdown(
    "2秒后进入登陆", () => {
      const { redirect } = tool.http.urlParse();
      const href = window.location.href;
      const redirectUrl = redirect || href;
      window.location.replace(`${window.location.origin}${setting.login}/?redirect=${redirectUrl}`);
    }
  )
}

// 更新token
const updateToken = () => {
  let option = {
    method: "PUT",
    url: `api/authorizations/current`,
    headers: {
      "content-type": 'application/x-www-form-urlencoded'
    }
  };
  let token = tool.storage.get("login-token");
  //解决Bearer丢失问题
  if (token && !token.startsWith("Bearer ")) {
    token = "Bearer ".concat(token);
  }
  option.headers.Authorization = token;

  option.data = qs.stringify({
    pid: env.pid,
    app_id: env.app_id
  });
  axios(option).then(r => {
    tool.storage.set("login-token", `${r.token_type} ${r.access_token}`);
    tool.storage.set("login-expires", r.expires_in);
    tool.storage.set("login-current", tool.time.timestamp());
  });
}

// 处理默认状态码
const handleDefaultStatusCode = (err, message) => {
  // 不弹出错误提示框
  if (err.data.code == 11) return;
  
  tc.err(message);
};

export default handleError;
