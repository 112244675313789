import http from '../basic'

const apis = {
    promotions: {
        get: (d) => http(`/api/promotions`, d),
        add: (d) => http(`/api/promotions`, d, 'post'),
        del: (id,d) => http(`/api/promotions/${id}`, d, 'DELETE'),

        // 获取分享链接
        url: (d) => http(`/api/promotions/url`, d),

        // 获取邀请码
        code: (d) => http(`/api/promotions/code`, d),

        // 获取统计
        stats: (d) => http(`/api/promotions/statistic`, d),
    },

    // 投放渠道
    deliveries: {
        get: (d) => http(`/api/deliveries`, d),
    },

    // 礼品
    gifts: {
        get: (d) => http(`/api/gifts`, d),
    },

    // 海报
    posters: {
        code: (d) => http(`/api/posters/code`, d, 'post'),
        mini: (d) => http(`/api/posters/mini`, d, 'post'),
    },

    // 微信
    wechat: {
        // 获取微信的jssdk配置信息
        jssdk: u => http(`/api/wechat/js_sdk`, {
            url: u,
            js_api_list: [
                "onMenuShareAppMessage",
                "onMenuShareTimeline",
                "onMenuShareQQ",
                "onMenuShareWeibo",
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "scanQRCode",
                "openLocation",
                "chooseLocation",
                "getLocation",
                "startRecord",
                "stopRecord",
                "onVoiceRecordEnd",
                "playVoice",
                "pauseVoice",
                "stopVoice",
                "onVoicePlayEnd",
                "uploadVoice"
            ]
        })
    }
}
export default apis