import tool from '@/common/tool';
import tc from '@/common/tool/tc';
import wechat from '@/common/tool/wx';
import router from '@/router';
import api from '@/api';


const login = {

	// 设置登录缓存
	cacheToken(r) {
		tool.storage.set('login-token', `${r.type} ${r.access_token}`);
		tool.storage.set('login-expires', r.expires_in);
		tool.storage.set('login-current', tool.time.timestamp());
	},
	// 存储用户信息
	cacheInfo(d) {
		tool.storage.set('user', d);
	},
	// 删除code
	removeCode() {
		tool.storage.remove('wx-code') && tool.storage.remove('wx-code-info');
	},
	// 跳转
	to(redirect = '/') {
		const path = [
			router.app.$route.path,
			'/mine/reg',
			'/mine/login',
			'/mine/password'
		];
		redirect = path.includes(redirect) ? '/' : redirect;
		window.location.replace(redirect);
	},

	// 检测是否登陆
	is(login) {
		let token = tool.storage.get("login-token");
		if (token) {
			return token
		} else {
			login && wechat.code(1);
		}
		return
	},

	// 登陆
	async into(newData) {
		const parse = tool.http.urlParse();
		let { code, state } = parse;
		let loginToken = await login.is();
		if (!loginToken && code) {
			let data = { code, ...newData };
			["wx-code", "wx-code-info"].includes(state) && await login.save(data)
			tc.show("登录成功")
		}
	},

	// 保存token
	async save(data) {
		// 尝试登陆
		let loginInfo = await api.user.login.wechat(data)
		if(!loginInfo.user.phone) {
			wechat.code(1, 0, 'wx-code-mobile');return;
		}

		// 缓存token
		login.cacheToken(loginInfo);

		// 保存用户信息
		login.cacheInfo(loginInfo.user);
	},
};

export default login;