
const create = (path, name, route, title, tabbar = false, keep = false) => {
    return {
        path,
        name,
        meta: {
            title,
            tabbar,
            keep,
        },
        component: () => import(`/src/views/${route}.vue`)
    };
}
export default create;