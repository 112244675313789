import wx from 'weixin-js-sdk';
import { showSuccessToast, showFailToast } from 'vant';
import router from '@/router';
import api from '@/api/index';
import qs from 'qs';

const HREF = window.location.href;
const WECHAT_SDK_URL = "https://open.weixin.qq.com/connect/oauth2/authorize";

// 默认分享参数
const defaultShareParams = {
    title: '首页',
    desc: '首页',
    imgUrl: 'https://pp.myapp.com/ma_icon/0/icon_10910_1706155319/256',
    link: HREF // 分享链接，初始化为空
};

const wechat = {
    // 配置微信 SDK

    async config() {
        try {
            const configData = await api.pro.wechat.jssdk(HREF);
            wx.config({
                debug: false,
                appId: configData.appId,
                timestamp: configData.timestamp,
                nonceStr: configData.nonceStr,
                signature: configData.signature,
                jsApiList: configData.jsApiList
            });
            return configData;
        } catch (error) {
            console.error('微信SDK配置失败', error);
            throw error;
        }
    },

    // 分享功能配置
    async share(shareData = defaultShareParams) {
        try {
            await this.config();
            wx.ready(() => {
                // 分享给好友
                wx.updateAppMessageShareData({ ...shareData });
                // 分享到朋友圈
                wx.updateTimelineShareData({ ...shareData });
            });
            wx.error((error) => {
                console.error('微信分享配置失败', error);
            });
        } catch (error) {
            console.error('分享失败', error);
        }
    },

    // 微信支付
    async payment(jsapiParams, orderDetails) {
        try {
            await this.config();
            return new Promise((resolve, reject) => {
                wx.chooseWXPay({
                    ...jsapiParams,
                    success: (result) => {
                        showSuccessToast('支付成功');
                        router.replace(`/pay/success/${orderDetails.id}/${orderDetails.pay_id}`);
                        resolve(result);
                    },
                    fail: (error) => {
                        showFailToast('支付失败');
                        reject(error);
                    }
                });
            });
        } catch (error) {
            console.error('支付配置失败', error);
        }
    },

    // 微信授权，用于获取code
    async code(type, url, state) {
        try {
            const configData = await this.config();
            let scope = type === 1 ? 'snsapi_userinfo' : 'snsapi_base';

            // 自定义参数值
            state = state || (type === 1 ? 'wx-code-info' : 'wx-code');
            url = url || HREF;

            // 删除URL中的code和state参数
            let { code, state: oldState, ...otherParams } = qs.parse(url.split('?')[1]);
            url = url.split('?')[0] + '?' + qs.stringify(otherParams);

            let authParams = qs.stringify({
                appid: configData.appId,
                redirect_uri: url,
                response_type: 'code',
                scope,
                state
            });
            window.location.href = `${WECHAT_SDK_URL}?${authParams}#wechat_redirect`;
        } catch (error) {
            console.error('微信授权失败', error);
        }
    },

    // 图片预览
    async previewImage(current, urls) {
        await this.config();
        wx.ready(() => {
            wx.previewImage({ current, urls });
        });
    },

    // 关闭当前窗口
    closeWindow() {
        wx.closeWindow();
    },
};

export default wechat;
