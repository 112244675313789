import { createRouter, createWebHistory } from 'vue-router'
import { closeToast } from 'vant';
import routes from './routes.js'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 在路由跳转之前执行的逻辑
router.beforeEach((to, from, next) => {

  // 关闭当前弹窗
  closeToast()

  // 修改页面标题
  document.title = to.meta.title
  next();
});

export default router
