import { createApp } from 'vue'
import App from './App.vue'
// vant4.0
import { Icon, TextEllipsis, Empty, Lazyload, Button, Overlay, Image } from 'vant';

// 重构路由
import router from './router'

// 全局store状态设置
import store from './store'

// 引入全局UI库
import 'vant/lib/index.css';
import './style.scss'

// 自动将css中的px转换成rem
import 'lib-flexible/flexible.js'

// 载入全局工具
import globalTools from '@/common/config/global-tools.js';

// 设置全局属性
const app = createApp(App);

// 加载全局组件
const plugins = [Icon, TextEllipsis, Empty, Button, Overlay, Image, store, router];
plugins.forEach(plugin => {
    app.use(plugin);
});

//图片懒加载
app.use(Lazyload, {
    loading: require("@/assets/loading.gif"), //图片加载的路径
    error: "", //图片加载失败时使用的图片源
    perload: 0,//预加载高度的比例
    attempt: 3 //尝试加载次数
});

// 映射全局工具
Object.entries(globalTools).forEach(([k, v]) => {
    app.config.globalProperties[`$${k}`] = v;
});

app.mount('#app');
