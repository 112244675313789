<template>
  <div class="app">
    <div class="app-overlay" v-show="!$store.state.appShow">
      <van-loading />
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive :include="keeps">
        <component :is="Component" :key="$route.name + $route.meta.savedPosition" v-if="$route.meta.keep" />
      </keep-alive>
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keep" />
    </router-view>


    <van-dialog v-model:show="loginForm.show" title="请绑定您的手机号码" theme="round-button" confirmButtonText="立即绑定"
      :confirm-button-disabled="loginForm.buttonDisabled"
      @confirm="bindMobile()">
      <van-cell-group inset>
        <van-field v-model="loginForm.mobile" label="手机号" placeholder="请输入手机号" maxlength="11" />
        <van-field v-model="loginForm.verification_code" label="验证码" placeholder="请输入验证码" maxlength="4">
          <template #button>
            <van-button size="small" type="primary" @click="getMobileCode()" :disabled="loginForm.codeDisabled">{{
              loginForm.codeButton }}</van-button>
          </template>
        </van-field>
      </van-cell-group>
    </van-dialog>


    <van-tabbar v-model="tabbar.active" v-if="$route.meta.tabbar">
      <van-tabbar-item icon="home-o">推广</van-tabbar-item>
      <van-tabbar-item icon="balance-o">业绩</van-tabbar-item>
      <van-tabbar-item icon="user-o">我的</van-tabbar-item>
    </van-tabbar>

  </div>
</template>
<script type="text/javascript" src="./app.js"></script>
<style src="./app.scss" lang="scss" scoped></style> 
