let exclude = ['./case.js'] // [`./routes/index.js`] 需要排除的文件
let objsContext = require.context('./routes/', false, /\.js$/);

const routes = objsContext.keys().reduce((all, file) => {
  // 忽略
  if (exclude.includes(file)) return all;

  // 导入接口配置文件
  const config = objsContext(file);

  // 将每个文件导出的数组合并到 all 数组中
  all.push(...(config.default || config));

  return all;
}, []);

export default routes;
