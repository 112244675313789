import axios from "axios";
import tool from "@/common/tool";
import env from "./config";
import store from "@/store";
import qs from "qs";
import handleError from "./handleError";

// 请求域名 从env配置中读取 信息
axios.defaults.baseURL = env.api;
// 请求超时 从env配置中读取 信息
axios.defaults.timeout = 300000;

// -----------返回值解析
axios.interceptors.response.use(
  config => {
    handleResponse(config);
    return config;
  },
  error => {
    handleError(error);
    return Promise.reject(error);
  }
);

// 处理正常响应
const handleResponse = config => {
  if (config.headers["event-id"] && config.headers["event-key"]) {
    tool.storage.set("headerInfo", config.headers);
  }
};

// 创建FormData
const createFormData = data => {
  let formData = new FormData();
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });
  return formData;
};

// 处理上传进度
const handleUploadProgress = progressEvent => {
  if (progressEvent.lengthComputable) {
    let progress = (progressEvent.loaded / progressEvent.total) * 100 || 0;
    store.commit("setProgress", progress);
  }
};

// 封装axios请求接口
const http = async (url = "", data = {}, method = "GET", fromData = true, isProgress) => {
  method = method.toUpperCase();
  fromData = method === "PATCH" ? false : fromData;

  // 从环境配置中获取信息
  data.page_path = window.location.href;

  // 如果是分享页面，则需要传递后端当前用户id_chart
  const { isShare } = tool.http.urlParse();
  if (parseInt(isShare) === 1) {
    // data.from_uid = data.from_uid || env.from_uid;
  }
  // 获取存储的token
  let token = tool.storage.get("login-token");

  if (token && !token.startsWith("Bearer ")) {
    token = "Bearer ".concat(token);
  }

  // 设置请求选项
  let option = {
    method,
    url,
    headers: {
      "content-type": 'application/x-www-form-urlencoded',
      Authorization: token
    },
    data: method === "GET" ? null : (fromData ? createFormData(data) : qs.stringify(data)),
    params: method === "GET" ? data : null,
  };

  // 如果isProgress为真，设置上传进度处理
  if (isProgress) option.onUploadProgress = handleUploadProgress;

  return new Promise((resolve, reject) => {
    // 删除旧的key，新的key增加前缀方便清除数据
    let key = `api_${url}?${qs.stringify(data)}`;
    tool.storage.remove(key);

    axios(option).then(res => {resolve(res.data);}).catch(err => {reject(err);});
  });
};

export default http;

