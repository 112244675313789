import login from "@/common/login";
import routes from '@/router/routes.js'
import { Tabbar, TabbarItem, showDialog, Dialog, Loading, CellGroup, Field, Button } from 'vant';

export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Loading.name]: Loading,
    [Dialog.name]: Dialog,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Button.name]: Button,
    showDialog
  },
  data() {
    return {
      bubble: false,
      offset: { x: 340, y: 300 },

      // 欢迎弹窗
      loginShow: false,
      defaultCodeName: '获取验证码',

      // 手机绑定窗口
      loginForm: {
        show: false, // 是否显示
        mobile: '',
        verification_key: '',
        verification_code: '',
        type: 2,
        code: '',
        share_code: '',
        codeDisabled: true,
        buttonDisabled: true,
        codeButton: '',
      },

      // 记录下来当前的路有
      keeps: [],

      // 菜单
      tabbar: {
        active: 0,
      }
    };
  },
  watch: {
    loginForm: {
      handler(n, o) {
        if (n.mobile.length == 11) {// && this.loginForm.codeButton == this.defaultCodeName
          (n.verification_key && n.verification_code.length == 4)
            ? n.buttonDisabled = false
            : n.buttonDisabled = true
          n.codeDisabled = false
        } else {
          n.codeDisabled = true
        }
      },
      deep: true,
      immediate: true,
    },
    $route(to, from) {

      // 登陆
      const unLogin = ["debug"];
      if (!unLogin.includes(to.name)) {
        this.login();
      }

      // 用户访问日志
      this.$log(to, from)

      // 从 sounds 返回 writings 时清除 sounds 的缓存
      this.clearKeep('sounds', 'writings', from, to);

    },
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick((n) => {
      setTimeout(() => {
        // 页面加载和挂载完毕后执行
        this.$store.commit("setAppShow", true);
      }, 500);
    });
  },
  methods: {

    // 初始化页面
    async init() {
      await this.setKeeps()
    },

    // 登陆
    async login() {
      const { code, state, share_code } = this.$tool.http.urlParse();
      if (!(await login.is()) && !code) {
        await showDialog({
          title: '泡泡合伙人',
          message: '共享人工智能学习时代红利',
          theme: 'round-button',
          confirmButtonText: '立即登陆',
        });
        login.is(true);
        return;
      }
    
      if (state === 'wx-code-mobile') {
        Object.assign(this.loginForm, { show: true, codeButton: this.defaultCodeName, code, share_code });
      } else {
        await login.into({ share_code });
      }
    },

    // 获取验证码
    async getMobileCode() {
      let info = await this.$api.user.login.code({ mobile: this.loginForm.mobile })
      if (info.key) {
        this.loginForm.verification_key = info.key
        let count = 30
        let timer = setInterval(() => {
          console.log(count);
          count--
          this.loginForm.codeButton = `${count}秒后重试`
          this.loginForm.codeDisabled = true
          if (count === 0) {
            clearInterval(timer)
            this.loginForm.codeButton = this.defaultCodeName
            this.loginForm.codeDisabled = false
          }
        }, 1000);
      }
    },

    // 绑定手机号码
    async bindMobile() {
      let tc = this.$tc.loading()
      let info = await this.$api.user.login.wechat(this.loginForm)

      // 缓存token
      login.cacheToken(info);

      // 保存用户信息
      login.cacheInfo(info.user);
      this.$tc.show("保存成功")
      tc.close()
    },

    // 设置需要缓存的页面
    setKeeps() {

      // 筛选出 meta.keep 为 true 的路由
      const keepRoutes = routes.filter(route => route.meta && route.meta.keep);

      // 创建包含这些路由 name 属性的数组
      this.keeps = keepRoutes.map(route => route.name);

    },

    // 清除缓存
    clearKeep(cleraName, tsName, from, to) {
      //如果不是从 Writings  访问 Sounds 清除缓存刷新接口数据
      if (from.name !== tsName) {
        const index = this.keeps.indexOf(cleraName);
        if (index !== -1) {
          this.keeps.splice(index, 1);
        }
      }

      // 如果从 Sounds 返回 Writings
      if (from.name === cleraName && to.name === tsName) {
        const index = this.keeps.indexOf(cleraName);
        if (index !== -1) {
          this.keeps.splice(index, 1);
        }
      }

      // 如果从 Writings 去往 Sounds
      if (from.name === tsName && to.name === cleraName) {
        // 确保 Sounds 页面被缓存
        if (!this.keeps.includes(cleraName)) {
          this.keeps.push(cleraName);
        }
      }
    }
  },
};