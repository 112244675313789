import http from '../basic'

const apis = {
    login: {
        // 通过微信的code获取token，code = 微信获取的code
        wechat: (d) => http(`/api/socials/wechat/authorizations`, d, 'post'),

        // 获取验证码
        code: (d) => http(`/api/verification_codes`, d, 'post'),

        // debug登录
        debug: (d) => http(`/api/authorizations/debug/login`, d, 'post'),

    }
}
export default apis