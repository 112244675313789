/** 全局配置文件 */

// 正式环境的host
const productionHost = `sales.shenmo-ai.com`

// 生产环境
const productionApi = `https://sales-api.shenmo-ai.com`

// 测试环境
const developmentApi = `https://sales-api-dev.shenmo-ai.com`

const currentEnvironment = window.location.host === productionHost ? "production" : "development";

const environments = {
    
    production: {
        api: productionApi,
    },

    development: {
        api: developmentApi,
    }
};
const config = environments[currentEnvironment];

export default config;