import tool from '@/common/tool'
import api from "@/api/index";
const log = (to, form) => {
    // IOS系统的特殊获取地址方法
    let isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isIos && !window.wechatShareUrl) {
        window.wechatShareUrl = `${window.location.origin}${to.fullPath}`;
    }
    // 记录用户的访问日志
    setTimeout(() => {
        // console.log(api);
        // api.common.userVisitLog({
        //     from: tool.storage.get("from", ""),
        //     path: to.fullPath,
        //     title: document.title,
        //     path_referer: form.fullPath
        // }).then(r => console.log('log:',r));
    }, 1000);
}
export default log
