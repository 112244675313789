import tool from '@/common/tool'
const oss = {

    // 缩放图片
	resize(src,num,t){
        t || (t = 'w');
        num || (num = 100);
        return src && `${src}?x-oss-process=image/resize,${t}_${num},m_lfit`;
    }
}
export default oss
