// 封装请求
import qs from "qs";
import tool from "@/common/tool";
import api from "@/api/index";
import wechat from '@/common/tool/wx'
import oss from '@/common/tool/oss'
import tc from '@/common/tool/tc'
import log from '@/common/log'

const share = (title = false, desc = false, img = false, link = false) => {
    wechat.share({
        title: title || '首页',
        desc: desc || "详情",
        images: img || "",
        link: link || window.location.href,
    });
}
export default {
    tool,
    qs,
    api,
    wechat,
    oss,
    tc,
    log,
    share
};