import { createStore } from 'vuex'
export default createStore({
  state: {
    // 页面是否准备好显示 是否自适应完毕 否则会导致抖动
    appShow: false,


    // 全局唯一的音频
    audioVux: {
      id: 0,
      isPlay: false,
      currentTime: 0,
      audio: {},
    },

    //最新上传完待合成的作品
    ossAudio: {
      src: '',
      time: 0
    },

    //支付loading
    showLoadingPay: null,
    //上传进度
    progress: 0,

    // 定时器
    timing: {
      timer: null,
      step: 5000,
    }


  },
  getters: {
  },
  mutations: {

    setAppShow(state, v) {
      state.appShow = v;
    },
    //设置上传进度
    setProgress(state, v) {
      state.progress = parseInt(v);
      // if (parseInt(state.progress) == 100) state.progress = 0;
    },

    // 音频操作
    audio(state, d = 'switch') {
      let ts = ["play", "pause", "switch", "stop"]
      let isPlay = state.audioVux.isPlay
      switch (ts.indexOf(d)) {
        case -1:
          isPlay && (
            (state.audioVux.audio.pause(), isPlay = false),
            (state.audioVux.audio.currentTime = 0)
          )
          state.audioVux.audio = new Audio(d);
          break;
        case 0:
          state.audioVux.audio.play(), isPlay = true
          break;
        case 1:
          state.audioVux.audio.pause(), isPlay = false
          break;
        case 2:
          isPlay
            ? state.audioVux.audio.pause()
            : state.audioVux.audio.play()
          isPlay = !isPlay
          break;
        case 3:
          state.audioVux.audio.pause(), isPlay = false
          state.audioVux.audio.currentTime = 0
          break;
      }
      state.audioVux.isPlay = isPlay
      if (isPlay) {
        state.audioVux.audio.addEventListener("timeupdate", function () {
          state.audioVux.currentTime = state.audioVux.audio.currentTime;
        });
        state.audioVux.audio.addEventListener("ended", function () {
          state.audioVux.isPlay = false;
        });
      }
    },

    //计时器
    setStep(state, step) {
      state.timing.step = step;
    },
    setTimer(state, timer) {
      state.timing.timer = timer;
    },
    clearTimer(state) {
      clearInterval(state.timing.timer);
      state.timing.timer = null;
    },

  },
  actions: {
    startTimer({ commit, state }, callback) {
      // 检查定时器是否已经启动
      if (state.timing.timer === null) {
        const timer = setInterval(() => {
          callback();
        }, state.timing.step);
        commit('setTimer', timer); // 保存定时器引用到 state
      }
    },
    stopTimer({ commit }) {
      commit('clearTimer');
    },
  },
  modules: {
  }
})
