import { showToast, showSuccessToast, showFailToast, showLoadingToast, closeToast, showNotify, closeNotify, showDialog } from 'vant';

const ToastController = {
  /**
   * 显示对话框，标题、消息和回调可以自定义。
   * @param {string} tip - 对话框中要显示的消息。
   * @param {string|function|boolean} isCall - 对话框的标题或回调函数。
   * @param {string} [button] - 确认按钮的文本。
   */
  dialog(tip = "提示", isCall, button = '确认') {
    let setting = {
      theme: 'round-button',
      confirmButtonText: button,
    };
    let isObj = false;

    // 检查 isCall 是否为函数、字符串或 undefined/null
    if (typeof isCall === 'function') {
      setting.title = tip;
      isObj = true;
    } else if (typeof isCall === 'string') {
      setting.title = tip;
      setting.message = isCall;
    } else if (isCall === undefined || isCall === null) {
      setting.message = tip;
    }

    showDialog(setting).then(() => {
      isObj && isCall();
    });
  },

  /**
   * 显示顶部警告类型的通知。
   * @param {string} text - 通知中要显示的消息。
   */
  top(text = '') {
    showNotify({
      type: 'warning',
      message: text,
    });
  },

  /**
   * 显示 toast 消息。
   * @param {string} text - toast 中要显示的消息。
   */
  show(text = '') {
    showToast(text);
  },

  /**
   * 显示成功的 toast 消息。
   * @param {string} text - 成功 toast 中要显示的消息。
   */
  suc(text = '正确') {
    showSuccessToast(text);
  },

  /**
   * 显示失败的 toast 消息。
   * @param {string} text - 失败 toast 中要显示的消息。
   */
  err(text = '错误') {
    showFailToast(text);
  },

  /**
   * 显示带有倒计时的加载 toast。
   * @param {string} msg - 初始消息，包括倒计时。
   * @param {function} [callback] - 倒计时结束时调用的函数。
   */
  loading(msg = '正在请求数据...', callback) {
    const toast = showLoadingToast({
      message: msg,
      forbidClick: true,
      duration: 10000,
    });
    return toast;
  },

  /**
   * 显示倒计时 toast 并在倒计时结束时执行回调。
   * @param {string} text - 初始消息，包括倒计时。
   * @param {function} callback - 倒计时结束时调用的函数。
   */
  countdown(text = `倒计时 3 秒`, callback) {
    let match = text.match(/\d+/g);
    let second = match ? parseInt(match[0]) : 3; // 如果没有匹配到数字，默认为3秒

    const toast = showLoadingToast({
      duration: 0,
      forbidClick: true,
      message: text,
    });

    const timer = setInterval(() => {
      second--;
      if (second) {
        toast.message = text.replace(/\d+/g, second);
      } else {
        callback && callback(); // 如果提供了回调函数，则在倒计时结束时调用
        clearInterval(timer);
        closeToast();
      }
    }, 1000);
  },

  /**
   * 关闭任何活动的 toast 或通知。
   */
  close() {
    closeToast();
    closeNotify();
  },
};

export default ToastController;